import React from "react";
import logo from "../images/6M513.png";

function PageNotFound() {
  return (
    <div>
        <img src={logo} width="100%" height="50%" />
    </div>
  );
}

export default PageNotFound;
