export const hourMint = {
    hours: [
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
    ],
    mint: [
        '05',
        '10',
        '15',
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        '55',
        '60',
    ],
}
export const testRoaster =[
    '120 NM Cross-country (for CPL)',
    '250 NM Cross-country (for CPL)',
    'AFIR Issue Test',
    'FIR Issue Test',
    'GFT by Day',
    'GFT by Night',
    'SLF release',
    'Annual Route check',
    'Instrument Rating Test',
    'Standardization/Examiner competency check',
    'Line release check (Initial)',
    'Pilot Proficiency Check (PPC)',
    'Skill Test by Day',
    'Skill Test by Night'
]